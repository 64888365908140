const allColors = {
  backgroundAndText: "#0f4544",
  highlight: "white",
  secondary: "#fff1d4",
  tertiary: "#99605c",
  scoreboardBackground: "#fff1d4",
};

export default allColors;

export const shuttle = (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="shuttlecock"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 512 512"
    class="svg-inline--fa fa-shuttlecock fa-w-16"
  >
    <path
      fill="currentColor"
      d="M484 192h-20c0 6 4.3 55.7-44.7 71.2L328.1 292l-26.4 45.4-78 36.3-25.7-25.8 70.8-70.8 140.8-44.5c13.3-4.2 22.4-16.6 22.4-30.5V108c0-15.5-12.5-28-28-28h-94.1c-14 0-26.3 9.1-30.5 22.4l-44.5 140.8-70.8 70.8-25.7-25.7 36.3-78 45.4-26.4 28.8-91.2C263.4 46.7 307.7 48 320 48V28c0-15.4-12.6-28-28-28h-54.6c-10.8 0-20.8 6.3-25.4 16.2L95.5 266.5l-39.2 39.2 150 150 39.2-39.2L495.8 300c9.8-4.6 16.2-14.5 16.2-25.4V220c0-15.4-12.6-28-28-28zM31.1 331c-41.4 41.4-41.4 108.6 0 150 41.4 41.4 108.6 41.4 150 0l2.6-2.6-150-150-2.6 2.6z"
      class=""
    ></path>
  </svg>
);

export const handshake = (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="fal"
    data-icon="handshake"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 640 512"
    class="svg-inline--fa fa-handshake fa-w-20"
  >
    <path
      fill="currentColor"
      d="M16 319.8c8.8 0 16-7.2 16-16s-7.2-16-16-16-16 7.2-16 16c0 8.9 7.2 16 16 16zM632 128l-113.5.2-51.2-49.9c-9.1-9.1-21.1-14.1-33.9-14.1h-101c-10.4 0-20.1 3.9-28.3 10-8.4-6.5-18.7-10.3-29.3-10.3h-69.5c-12.7 0-24.9 5.1-33.9 14.1l-50 50H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h56v191.9H8c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h56c17.6 0 31.8-14.2 31.9-31.7h33.2l81.5 78c29.8 24.1 71.8 23.4 101-.2l7.2 6.2c9.6 7.8 21.3 11.9 33.5 11.9 16 0 31.1-7 41.4-19.6l21.9-26.9c16.4 8.9 42.9 9 60-12l9.5-11.7c6.2-7.6 9.6-16.6 10.5-25.7h48.6c.1 17.5 14.4 31.7 31.9 31.7h56c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8h-56V160.2l56-.2c4.4 0 8-3.6 8-8v-16c-.1-4.5-3.7-8-8.1-8zM460.2 357.6l-9.5 11.7c-5.4 6.6-15.4 8.1-22.5 2.3l-17.8-14.4-41.5 51c-7.5 9.3-21 10.2-29.4 3.4l-30.6-26.1-10.4 12.8c-16.7 20.5-47 23.7-66.6 7.9L142 320.1H96V159.9h38.6l59.3-59.3c3-3 7.1-4.7 11.3-4.7h69.5c.9 2.2.3.7 1.1 2.9l-59 54.2c-28.2 25.9-29.6 69.2-4.2 96.9 14.3 15.6 58.6 39.3 96.9 4.2l22.8-20.9 125.6 101.9c6.8 5.6 7.8 15.7 2.3 22.5zm83.8-37.5h-57.2c-2.5-3.5-5.3-6.9-8.8-9.8l-121.9-99 28.4-26.1c6.5-6 7-16.1 1-22.6s-16.1-6.9-22.6-1l-75.1 68.8c-14.4 13.1-38.6 12-51.7-2.2-13.6-14.8-12.7-38 2.2-51.7l83.1-76.2c3-2.7 6.8-4.2 10.8-4.2h101c4.3 0 8.3 1.7 11.4 4.8l60.7 59.1H544v160.1zm80-32.2c-8.8 0-16 7.2-16 16s7.2 16 16 16 16-7.2 16-16c0-8.9-7.2-16-16-16z"
      class=""
    ></path>
  </svg>
);
